// Generated by Framer (2add0ca)

import { addFonts, cx, CycleVariantState, getFonts, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/CAjjxbTJBxHwH1MagCef/Phosphor.js";
const PhosphorFonts = getFonts(Phosphor);

const enabledGestures = {g_lVRJOpi: {hover: true}};

const cycleOrder = ["g_lVRJOpi"];

const variantClassNames = {g_lVRJOpi: "framer-v-15feull"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "g_lVRJOpi", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "g_lVRJOpi", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-YS4LJ", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-15feull", className)} data-border data-framer-name={"Default"} layoutDependency={layoutDependency} layoutId={"g_lVRJOpi"} ref={ref} style={{"--border-bottom-width": "1px", "--border-color": "var(--token-68c05b50-ca7b-4173-82aa-ed42aea1a9b4, rgba(218, 197, 167, 0.15))", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backdropFilter: "blur(15px)", backgroundColor: "rgb(230, 230, 230)", borderBottomLeftRadius: 4, borderBottomRightRadius: 4, borderTopLeftRadius: 4, borderTopRightRadius: 4, WebkitBackdropFilter: "blur(15px)", ...style}} variants={{"g_lVRJOpi-hover": {"--border-color": "rgb(23, 23, 23)", backgroundColor: "rgba(44, 33, 51, 0.5)"}}} {...addPropertyOverrides({"g_lVRJOpi-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><motion.div className={"framer-1gc39eb-container"} layoutDependency={layoutDependency} layoutId={"csa4TAvAP-container"}><Phosphor color={"var(--token-c5c4fdcb-9482-48bb-aedf-353188472aae, rgb(218, 197, 167)) /* {\"name\":\"text/default\"} */"} height={"100%"} iconSearch={"twitter"} iconSelection={"TwitterLogo"} id={"csa4TAvAP"} layoutId={"csa4TAvAP"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={"light"} width={"100%"}/></motion.div></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-YS4LJ [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-YS4LJ .framer-1uvif1n { display: block; }", ".framer-YS4LJ .framer-15feull { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 32px; height: 40px; justify-content: center; overflow: visible; padding: 8px 12px 8px 12px; position: relative; width: 40px; }", ".framer-YS4LJ .framer-1gc39eb-container { flex: none; height: 18px; position: relative; width: 18px; }", ".framer-YS4LJ .framer-v-15feull .framer-15feull { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-YS4LJ .framer-15feull { gap: 0px; } .framer-YS4LJ .framer-15feull > * { margin: 0px; margin-bottom: calc(32px / 2); margin-top: calc(32px / 2); } .framer-YS4LJ .framer-15feull > :first-child { margin-top: 0px; } .framer-YS4LJ .framer-15feull > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 40
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"fMHqw4x0Z":{"layout":["fixed","fixed"]}}}
 */
const Framersst00XF3z: React.ComponentType<Props> = withCSS(Component, css, "framer-YS4LJ") as typeof Component;
export default Framersst00XF3z;

Framersst00XF3z.displayName = "Badge Icon Link";

Framersst00XF3z.defaultProps = {height: 40, width: 40};

addFonts(Framersst00XF3z, [...PhosphorFonts])